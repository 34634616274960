import debounce from "lodash/debounce";

(function () {
  const $document = document,
    $body = $document.getElementsByTagName("BODY")[0],
    $header = $document.querySelector("[data-header]");
  let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) && !window.MSStream;

  function checkPos() {
    let distance = window.pageYOffset,
      headerHeight = $header.offsetHeight,
      distanceTrigger = headerHeight; // Change to desired scroll offset to trigger style change (use headerHeight to change when scrolled passed header)

    distance > distanceTrigger ? $header.classList.add("is-scrolled") : $header.classList.remove("is-scrolled"); // Add "is-fixed" to make the header fixed to top after scroll

    if ($header.classList.contains("c-header--bottom")) { // If we have a bottom header, hide it on scroll

      if (!isIOS) { // If not iOS hide it immediately
        (distance > lastDistance) & (distance > 0) & !$header.classList.contains("is-active") ? $header.classList.add("is-hidden") : $header.classList.remove("is-hidden");
        lastDistance = distance;
      } else { // If iOS check if the toolbar is present before hiding
        let newWindowHeight = Math.max(window.innerHeight);
        if(newWindowHeight-50 > baseWindowHeight) {
          if (!$body.classList.contains("no-toolbar")) {
            $body.classList.add("no-toolbar");
            if (!$header.classList.contains("is-active")) {
              $header.classList.add("is-hidden");
            }
          }
        } else {
          if ($body.classList.contains("no-toolbar")) {
            $body.classList.remove("no-toolbar");
            if (!$header.classList.contains("is-active")) {
              $header.classList.remove("is-hidden");
            }
          }
        }
      }

    }

  }

  function checkStyle() {
    if ($header.classList.contains("c-header--bottom")) {
      $body.classList.add("has-bottom-header");
    }
    if ($header.classList.contains("c-header--fixed") || $header.classList.contains("c-header--overlay")) {
      $body.classList.add("has-fixed-header");
    }
  }

  function getHeaderHeight() {
    let hh = $header.offsetHeight;
    if (!$header.classList.contains("is-active")) {
      document.documentElement.style.setProperty("--header-height", `${hh}px`);
    }
  }

  (function init() {
    lastDistance = window.pageYOffset;
    baseWindowHeight = Math.max(window.innerHeight);

    getHeaderHeight();
    checkPos();
    checkStyle();

    window.addEventListener("resize", debounce(getHeaderHeight));
    window.addEventListener("scroll", debounce(checkPos));
  })();
  
})();
