import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

(function () {
  const $document = document,
    $body = $document.body,
    $bodyEl = $document.getElementsByTagName("BODY")[0],
    $header = $document.querySelector("[data-header]"),
    $main = $document.querySelector("[data-content]");

  function toggleMainMenu() {
    let toActivate = [$body, $header, $main],
      headerHeight = $header.offsetHeight;

    this.classList.toggle("is-active");

    toActivate.forEach(el => el.classList.toggle("is-active"));
    toActivate.forEach(el => el.classList.toggle("is-animating"));

    if ($header.classList.contains("is-active")) {
      disableBodyScroll($header);
      if (!$header.classList.contains("c-header--fixed") && !$bodyEl.classList.contains("has-bottom-header")) {
        $bodyEl.style.paddingTop = headerHeight + "px";
      }
    } else {
      clearAllBodyScrollLocks();
      $bodyEl.removeAttribute("style");
    }

    // Remove is-animating if mobile navigation finished sliding in
    $header.addEventListener("transitionend", function onEnd(event) {
      if (event.propertyName == "left") { // Change this if direction or transition changes
        toActivate.forEach(el => el.classList.remove("is-animating"));
        $header.removeEventListener("transitionend", onEnd, false);
      }
    }, false);

  }

  (function init() {
    clearAllBodyScrollLocks();
    if (typeof($header) != "undefined" && $header != null) {
      const $mobileTrigger = $header.querySelector("[data-trigger-mobile]");
      $mobileTrigger.addEventListener("click", toggleMainMenu);
      $mobileTrigger.addEventListener("transitionstart",(e)=>e.stopPropagation());
      $mobileTrigger.addEventListener("transitionend",(e)=>e.stopPropagation());
    }
  })();
})();
