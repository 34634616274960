import baguetteBox from "baguettebox.js";

(function () {
  const $lightbox = "[data-gallery]";

  function setLightbox() {
    baguetteBox.run($lightbox, {
      caption: false
    });
  }

  (function init() {
    if (typeof($lightbox) != "undefined" && $lightbox != null) {
      window.addEventListener("load", setLightbox);
    }
  })();
})();
