import axios from "axios";

(function () {
  const $document = document,
    $newsletter = $document.querySelectorAll(".c-newsletter__form");

  function resetValidation(form) {
    if (form.target) form = form.target.closest(".c-newsletter__form");
    const $feedback = form.querySelector(".c-form__feedback");

    if (typeof($feedback) != "undefined" && $feedback != null) {
      $feedback.style.display = "none";
    }
  }

  function submitForm (e) {
    e.preventDefault();
    const form = e.target.closest(".c-newsletter__form");

    let url = form.getAttribute("data-url"),
      $error = form.querySelector(".c-form__feedback"),
      data = new FormData(form);

    axios({
      method: "POST",
      url: url,
      data: data
    })
      .then(function (response) {
        if (response.data.success) {
          callSuccess();
        } else {
          callFail(response);
        }
      })
      .catch(function (err) {
        $error.innerHTML = err.message;
      });

    function callSuccess() {
      const $alert = form.querySelector(".c-alert"),
        $input = form.querySelector("#newsletter_email");

      $alert.classList.remove("is-hidden");
      setInterval(() => { $alert.classList.add("is-hidden"); }, 3000);
      $input.value = "";
      resetValidation();
    }

    function callFail(response) {
      $error.style.display = "block";
      $error.innerHTML = response.data.errors.email[0];
    }
  }

  (function init () {
    $newsletter.forEach(form => {
      form.addEventListener("submit", submitForm);
      form.querySelector("input[type='email']").addEventListener("focus", resetValidation);
      form.querySelector("input[type='email']").addEventListener("click", resetValidation);
      resetValidation(form);
    });
  })();
})();
