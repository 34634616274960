(function () {
  const $document = document;

  function setLink(e) {
    let href = this.prop("href"),
      params = "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=580,height=325";

    e.preventDefault();
    window.open(href, "social", params);

    return false;
  }

  (function init() {
    const $links = $document.querySelectorAll("[data-pop-up] a");

    $links.forEach(link => { link.addEventListener("click", setLink); });
  })();
})();
