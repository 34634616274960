import tippy from "tippy.js";

(function () {
  const $document = document;

  tippy($document.querySelectorAll("[data-tippy-content]"), {
    animation: "shift-away",
    arrow: true,
    arrowType: "sharp",
    delay: 20,
    duration: 200,
    size: "large",
    theme: "yappa"
  });
})();
