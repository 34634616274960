(function () {
  const $document = document,
    $forms = $document.querySelectorAll(".c-form");

  function focusLabel(e) {
    let $inputs = this.querySelectorAll("input[type=text], textarea, select"),
      $feedback = this.querySelector(".c-form__feedback");

    e.stopPropagation();

    $inputs.forEach(input => { input.classList.remove("c-form--danger__control"); });

    this.classList.remove("c-form--danger");
    if (typeof($feedback) != "undefined" && $feedback != null) {
      $feedback.style.display = "none";
    }
  }

  (function init() {

    $forms.forEach(form => {
      const $groups = form.querySelectorAll(".c-form__group");
      $groups.forEach(group => { group.addEventListener("click", focusLabel); });
    });

  })();
})();
