(function () {
  const $document = document,
    $uploads = $document.querySelectorAll("[data-upload]");

  function updateField(e) {
    let $label = this.parentNode.querySelector("label"),
      $span = $label.querySelector("span"),
      spanVal = $span.innerText,
      fileName = e.target.value.split( "\\" ).pop();

    fileName ? $span.innerText = fileName : $span.innerText = spanVal;
  }

  (function init() {
    $uploads.forEach(upload => {
      let $input = upload.querySelector("input");

      $input.addEventListener("change", updateField);
    });
  })();
})();
