import Cookies from "js-cookie";
import axios from "axios";
(function () {
  const $document = document,
    $html = $document.querySelector("html"),
    $cookie = $document.querySelector("[data-cookie]"),
    $cookieAjax = $document.querySelector("[data-modal=modal-cookie]");
  function checkCookie() {
    $cookie.classList.remove("is-hidden");
    Cookies.get("cookieNotification") !== "seen" ? $cookie.classList.remove("is-hidden") : $cookie.classList.add("is-hidden");
  }
  function closeCookie() {
    $cookie.classList.add("is-hidden");
    Cookies.set("cookieNotification", "seen", {expires: 180});
  }
  function focusCookieModal(e) {
    let targetElement = e.target;
    if (targetElement.classList.contains("c-modal--cookie") && targetElement.classList.contains("is-active")) {
      closeCookieModal();
    }
  }
  function closeCookieModal() {
    let $modal = $document.querySelector(".c-modal--cookie");
    $modal.classList.remove("is-active");
    $html.classList.remove("is-hidden");
  }
  function loadAdvancedCookieNotice() {
    let $view = $cookieAjax.querySelector(".c-modal__view");
    $view.classList.remove("is-hidden");
    loadCookieSettings();
    let $modalHolder = $document.querySelector(".c-modal--cookie"),
      $closers = $document.querySelectorAll("[data-modal-close]"),
      $allBtn = $document.querySelector("[data-modal-all]"),
      $acceptAllBtn = $cookie.querySelector("[data-modal-all-btn]"),
      $saveBtn = $document.querySelector("[data-modal-save]");
    $modalHolder.addEventListener("click", focusCookieModal);
    $closers.forEach(closer => { closer.addEventListener("click", closeCookieModal); });
    $allBtn.addEventListener("click", checkAllCookieSettings);
    $saveBtn.addEventListener("click", cookieSettings);
    $acceptAllBtn.addEventListener("click", checkAllCookieSettings);
    function checkAllCookieSettings() {
      let $settings = $document.querySelectorAll("[data-gtm]");
      if (typeof($settings) != "undefined" && $settings != null) {
        $settings.forEach(setting => { setting.querySelector(".c-form__consensus__label input").checked = true; });
      }
      saveCookieSettings();
    }
    function cookieSettings(e) {
      e.preventDefault();
      saveCookieSettings();
    }
    function saveCookieSettings() {
      closeCookie();
      closeCookieModal();
      let $settings = $document.querySelectorAll("[data-gtm]"),
        settings = [];
      for(let i = 0; i < $settings.length; i++) {
        if ($settings[i].querySelector(".c-form__consensus__label input").checked) {
          settings[i] = $settings[i].getAttribute("data-gtm");
        }
      }
      Cookies.set("cookieSettings", JSON.stringify(settings), {expires: 180});
      loadCookieSettings();
    }
    function loadCookieSettings() {
      let cookies = Cookies.get("cookieSettings");
      if (typeof(cookies) != "undefined" && cookies != null) {
        const cookieSavedSettings = JSON.parse(Cookies.get("cookieSettings"));
        let cookieCodes = "";
        for (let i = 0; i < cookieSavedSettings.length; i++) {
          cookieCodes += cookieSavedSettings[i] + " ";
        }
        let savedCookies = cookieSavedSettings.map( function(i) { return document.getElementById(i) } );
        if (typeof savedCookies != "undefined" && savedCookies != null) {
          savedCookies.forEach(function (savedCookie) {
            if (typeof savedCookie != "undefined" && savedCookie != null) {
              savedCookie.checked = true;
            }
          });
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({"event": "Cookies Accept", "cookies": cookieCodes});
      }
    }
  }
  (function init() {
    const $close = $cookie.querySelectorAll("[data-cookie-close]");
    if (typeof($close) != "undefined" && $close != null) {
      $close.forEach(close => { close.addEventListener("click", closeCookie); });
    }
    if (typeof($cookieAjax) != "undefined" && $cookieAjax != null) {
      loadAdvancedCookieNotice($cookieAjax);
    }
    checkCookie();
  })();
})();
