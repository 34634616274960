import Dropzone from "dropzone";
import axios from "axios";

Dropzone.autoDiscover = false;

(function () {
  const $document = document;

  function fileUpload(target) {
    let dropzone = null;

    let fileList = target.querySelector("[data-dropzone-file-list]"),
      dropzoneContainer = target.querySelector("[data-dropzone-container]");

    // Skip if dropzone is already attached
    if (dropzoneContainer.getAttribute("dropzone-attached")) {
      return;
    }

    let options = getOptions(dropzoneContainer),
      messages = getMessages(dropzoneContainer);

    // Instantiate dropzone
    new Dropzone(dropzoneContainer, {
      addRemoveLinks: true,
      url: options.uploadUrl,
      maxFiles: options.maxFiles,
      uploadMultiple: options.multiple,
      acceptedFiles: options.acceptedTypes,

      dictRemoveFile: messages.remove,
      dictFileTooBig: messages.tooBig,
      dictCancelUpload: messages.cancel,
      dictInvalidFileType: messages.notSupported,

      init: function () {
        dropzone = this;

        removeEmptyFields(fileList);
        loadPreviews(dropzone, fileList);
        dropzoneContainer.setAttribute("dropzone-attached", true);
      }
    });

    // Dropzone events
    dropzone.on("sending", function () {
      toggleMessage(false);
    });

    dropzone.on("success", function (file, response) {
      if (!file.mocked && response.hasOwnProperty("id")) {
        file.id = response.id;

        dropzone.emit("thumbnail", file, response.path);
        appendFile(file);
      }
    });

    dropzone.on("error", function (file) {
      (file.previewElement).querySelector(".dz-error-message").classList.add("error-border");
    });

    dropzone.on("removedfile", function (removeFile) {
      let files = fileList.children;

      if (files.length !== null && files.length > 0) {
        [...files].forEach(file => {
          if (file.value === removeFile.id) {
            file.parentNode.removeChild(file);
          }
        });
      }

      toggleMessage(fileList.children.length < 1);
    });

    dropzone.on("maxfilesexceeded", function (file) {
      if (options.maxFiles === 1) {
        this.removeAllFiles();
        toggleMessage(false);
        this.addFile(file);
        return;
      }

      (file.previewElement).querySelector(".dz-error-message").classList.add("error-border");
      (file.previewElement).querySelector(".dz-error-message").innerHTML = "<span>" + messages.maxFilesReached + "</span>";
    });

    // disable Submit button to prevent sending form without file data
    dropzone.on("queuecomplete", function () {
      let $submitBtns = target.querySelectorAll("[type=submit]");

      $submitBtns.forEach(submitBtn => submitBtn.classList.remove("is-disabled"));
    });

    dropzone.on("addedfile", function (file) {
      let $submitBtns = target.querySelectorAll("[type=submit]");

      if (!file.hasOwnProperty("mocked")) {
        $submitBtns.forEach(submitBtn => submitBtn.classList.add("is-disabled"));
      }
    });

    dropzone.on("processing", function () {
      let $submitBtns = target.querySelectorAll("[type=submit]");

      $submitBtns.forEach(submitBtn => submitBtn.classList.add("is-disabled"));
    });

    // Helpers
    function getOptions(container) {
      let optionsContainer = container.querySelector("[data-upload-settings]");

      return {
        maxSize: optionsContainer.dataset.maxSize,
        maxFiles: optionsContainer.dataset.maxFiles,
        uploadUrl: optionsContainer.dataset.uploadUrl,
        previewUrl: optionsContainer.dataset.previewUrl,
        acceptedTypes: optionsContainer.dataset.allowedTypes,
        multiple: (target.maxFiles > 1)
      };
    }

    function getMessages(container) {
      let messagesContainer = container.querySelector("[data-preview-messages]");

      return {
        remove: messagesContainer.dataset.dzRemove,
        cancel: messagesContainer.dataset.dzCancel,
        tooBig: messagesContainer.dataset.dzTooBig,
        notSupported: messagesContainer.dataset.dzNotSupported,
        maxFilesReached: messagesContainer.dataset.dzMaxFilesReached,
      };
    }

    // Remove options with empty values
    function removeEmptyFields(fileList) {
      let files = fileList.children;

      if (files.length !== null && files.length > 0) {
        [...files].forEach(file => {
          if (!file.value) {
            file.parentNode.removeChild(file);
          }
        });
      }
    }

    // If the list contains files, load their previews
    function loadPreviews(dropzone, fileList) {
      let files = fileList.children;
      toggleMessage(files.length < 1);

      [...files].forEach(file => {
        let fileId = file.value;

        axios.get(options.previewUrl.replace(/__id__/g, fileId))
          .then(file => {
            mockFile(file.data, dropzone);
          })
          .catch(() => {
            file.remove();
          });
      });
    }

    function mockFile(file, dropzone) {
      let mockedFile = Object.assign({ mocked: true, accepted: true }, file);

      dropzone.emit("addedfile", mockedFile);
      dropzone.emit("thumbnail", mockedFile, file.path);
      dropzone.emit("success", mockedFile);
      dropzone.emit("complete", mockedFile);
      dropzone.files.push(mockedFile);
    }

    function appendFile(file) {
      let option = "<option selected></option>";

      fileList.insertAdjacentHTML("beforeend", option);
      fileList.querySelector("option").setAttribute("value", file.id);
    }

    // Toggle the main message
    function toggleMessage(toggle) {
      dropzoneContainer.querySelector(".dz-message").classList.toggle(toggle);
    }
  }

  (function init() {
    const $dropzones = $document.querySelectorAll("[data-dropzone-holder]");

    $dropzones.forEach(dropzone => fileUpload(dropzone));
  })();
})();
