import smoothscroll from "smoothscroll-polyfill";

(function () {
  const $document = document;

  function gotoAnchor(e) {
    const $html = $document.querySelector("html, body");
    e.preventDefault();

    let $href = this.getAttribute("href"),
      $loc = document.querySelector("" + $href),
      loc = window.pageYOffset + $loc.getBoundingClientRect().top - 20;

    $html.scrollTo({
      top: loc,
      left: 0,
      behavior: "smooth"
    });
  }

  (function init() {
    const $anchors = $document.querySelectorAll("[data-href]");

    $anchors.forEach(anchor => { anchor.addEventListener("click", gotoAnchor); });
    smoothscroll.polyfill();
  })();
})();
