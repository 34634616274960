import axios from "axios";

(function () {
  const $document = document,
    $downloads = $document.querySelectorAll("[data-request=download]"),
    $html = $document.querySelector("html");
  let verified = false,
    verifyUrl = "",
    modalId = "",
    $target = "",
    formUrl = "";

  function requestDownload(e) {
    $target = this;
    if (verified === true) {
      window.location = $target.getAttribute("href");
      return true;
    }

    e.preventDefault();
    verifyUrl = $target.dataset.verifyUrl;

    axios.get(verifyUrl)
      .then(function (response) {
        verifyRequest(response, $target);
      })
      .catch(function () {});
  }

  function verifyRequest(data, link) {
    if (data.verified === true) {
      verified = true;
      link.click();
      return;
    }

    modalId = link.dataset.modalId;
    formUrl = link.dataset.formUrl;

    axios.get(formUrl)
      .then(function (response) {
        buildModal(response, modalId, link);
      })
      .catch(function () {});
  }

  function buildModal(response, modalId, link) {
    const $modalTarget = $document.querySelector("#" + modalId),
      $viewHolder = $modalTarget.querySelector("[data-ajax-modal-load]");

    $viewHolder.innerHTML = response.data.html;
    $html.classList.add("is-hidden");
    $modalTarget.classList.add("is-active");

    let $requestForm = $viewHolder.querySelector("form");
    $requestForm.addEventListener("submit", function(e) {
      e.preventDefault();
      requestSubmit(this, link);
    });
  }

  function requestSubmit(form, link) {
    let data = new FormData(form),
      url = link.dataset.formUrl;

    axios({
      method: "POST",
      url: url,
      data: data
    })
      .then(function (response) {
        if (response.data.success) {
          window.location.href = $target;

          let $modalTarget = $document.querySelector("#" + modalId);
          $modalTarget.classList.remove("is-active");
          $html.classList.remove("is-hidden");
        } else {
          buildModal(response, modalId, link);
        }
      })
      .catch(function () {});
  }

  (function init() {
    $downloads.forEach(download => { download.addEventListener("click", requestDownload); });
  })();
})();
