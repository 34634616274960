import StickySidebar from "sticky-sidebar/dist/sticky-sidebar.js";

(function () {
  const $document = document,
    $sticky = $document.querySelector("[data-sticky]"),
    breakpoint = 767;

  function checkSticky(width) {
    if (typeof($sticky) != "undefined" && $sticky != null) {
      if (width > breakpoint) {
        setFaqFixed();
      }
    }
  }

  function setFaqFixed() {
    new StickySidebar($sticky, {
      bottomSpacing: 20,
      topSpacing: 20,
      containerSelector: ".sticky-holder",
      innerWrapperSelector: ".c-sidebar"
    });
  }

  function resizeWindow() {
    let newViewportWidth = window.innerWidth || document.documentElement.clientWidth;

    checkSticky(newViewportWidth);
  }

  (function init() {
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    checkSticky(viewportWidth);
    window.addEventListener("resize", resizeWindow);
  })();
})();
