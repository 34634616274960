window.onmessage = (e) => {
  console.log(e.data.iframeId);
  if (e.data.hasOwnProperty("frameHeight")) {
    if (e.data.iframeId == "pardot-form") {

      document.getElementById("iframe-resize").style.height = `${e.data.frameHeight + 60}px`;
    }
    if (e.data.iframeId == "pardot-form-footer") {

      document.getElementById("iframe-footer").style.height = `${e.data.frameHeight + 30}px`;
    }
  }

};
