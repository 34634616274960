(function () {
  const $document = document,
    $html = $document.querySelector("html");

  function focusModal(e) {
    let targetElement = e.target;

    if (targetElement.classList.contains("c-modal") && targetElement.classList.contains("is-active")) {
      closeModal();
    }
  }

  function closeModal() {
    let $modals = $document.querySelectorAll(".c-modal");

    $modals.forEach(modal => { modal.classList.remove("is-active"); });
    $html.classList.remove("is-hidden");
  }

  function openModal() {
    let $modal = this.getAttribute("data-modal-trigger"),
      $target = $document.querySelector("#" + $modal);

    $target.classList.add("is-active");
    $html.classList.add("is-hidden");
  }

  function checkModal() {
    let $modalErrors = $document.querySelectorAll(".c-modal .c-form__group"),
      url = window.location.href;

    $modalErrors.forEach(modalError => {
      if (modalError.classList.contains("c-form--danger")) {
        let $modal = modalError.closest(".c-modal");

        $modal.classList.add("is-active");
        $html.classList.add("is-hidden");
      }
    });

    if (url.indexOf("#form-success-") >= 0) {
      let formId = url.split("#form-success-").pop(),
        $modal = $document.querySelector("#modal-" + formId);

      if (typeof($modal) != "undefined" && $modal != null) {
        $form = $modal.querySelector(".c-form");

        $modal.classList.add("is-active");
        $form.classList.add("c-form--success");
        $html.classList.add("is-hidden");
      }
    }
  }

  (function init() {
    const $triggers = $document.querySelectorAll("[data-modal-trigger]"),
      $modalHolders = $document.querySelectorAll("[data-modal]"),
      $closers = $document.querySelectorAll("[data-modal-close]");

    $triggers.forEach(trigger => { trigger.addEventListener("click", openModal); });
    $modalHolders.forEach(modalHolder => { modalHolder.addEventListener("click", focusModal); });
    $closers.forEach(closer => { closer.addEventListener("click", closeModal); });
    checkModal();
  })();
})();
