(function () {
  const $document = document,
    $readMores = $document.querySelectorAll("[data-toggle=dropdown]"),
    $readMoresChildren = $document.querySelectorAll("[data-toggle=dropdown] > *");

  function removeReadMore() {
    let $targets = $document.querySelectorAll("[data-target]"),
      $dropdowns = $document.querySelectorAll("[data-toggle=dropdown]");

    $targets.forEach((target) => { target.classList.remove("is-active"); });
    $dropdowns.forEach((dropdown) => {
      dropdown.classList.remove("is-active");
      dropdown.setAttribute("aria-expanded", false);
    });
  }

  function toggleReadMore() {
    let $id = this.id,
      $content = $document.querySelector("[data-target=" + $id + "]");

    $content.classList.toggle("is-active");
    this.classList.toggle("is-active");

    this.classList.contains("is-active") ? this.setAttribute("aria-expanded", true) : this.setAttribute("aria-expanded", false);

    $content.addEventListener("focusout", removeReadMore);

    return false;
  }

  function blurReadMore(e) {
    let targetElement = e.target,
      attrDropdown = targetElement.getAttribute("data-toggle");

    if (!(attrDropdown === "dropdown")) {
      removeReadMore();
    }
  }

  function disableClicks(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  (function init() {
    $document.addEventListener("click", blurReadMore);
    $readMores.forEach(readMore => { readMore.addEventListener("click", toggleReadMore); });
    $readMoresChildren.forEach(readMoreChild => { readMoreChild.addEventListener("click", disableClicks); });
  })();
})();
